import {
  API_AUTH_LOGIN,
  API_ALERTS_UN,
  API_ALL_INFO,
  API_ALERTS,
  API_INFOS,
  API_AREA_COUNTRIES,
  API_AREA_REGIONS,
  API_INFOS_GROUPED,
  API_INFO_TO_REVIEW
} from "./endpoint";

//
const CONTENT_TYPES = { json: "application/json; charset=utf8" };

// const config = {server: 'https://api.cap.test', api: '/v1'}
const config = {
  server: process.env.REACT_APP_CAP_HOST,
  api: process.env.REACT_APP_CAP_VAPI
};
const serverApi = `${config.server}${config.api}`;

/**
 * Description DRF user authentication by JWT
 * @param {string} username
 * @param {string} password
 * @return {object} user property
 */
export const userAuth = async (username, password) => {
  const options = {
    method: "POST",
    headers: { "Content-Type": CONTENT_TYPES.json },
    body: JSON.stringify({ username, password })
  };
  const response = await fetchApi(`${serverApi}${API_AUTH_LOGIN}`, options);

  if (response.hasOwnProperty("token")) {
    return {
      auth: true,
      username: response.user.username,
      errors: null,
      data: response.user
    };
  }
  return { auth: false, errors: response };
};

/**
 * Description get all data form combo form
 * @param {object} user
 * @return {object} allData values
 */
export const getAllData = async user => {
  const { error, options } = setOptions(user);
  if (error) return { erros: error };
  try {
    const allInfo = await Promise.all(
      API_ALL_INFO.map(url => fetchApi(`${serverApi}${url}`, options))
    );
    const alerts = { results: [] }; // await fetchApi(`${serverApi}${API_ALERTS_UN}`, options);
    /*
    const infosGroupedUN = await fetchApi(
      `${serverApi}${API_INFOS_GROUPED}?status=UN`,
      options
    );
    */
    return {
      errors: null,
      alerts,
      info: allInfo,
      apiInfo: API_ALL_INFO
      // infosGroupedUN
    };
  } catch (error) {
    return { errors: error, alerts: null, info: null, infosGroupedUN: null };
  }
};

export const getAlerts = async (user, params = "") => {
  const { error, options } = setOptions(user);
  if (error) return { erros: error };
  const item = await fetchApi(`${serverApi}${API_ALERTS}${params}`, options);
  return item;
};

export const getAllUnassigned = async user => {
  const { error, options } = setOptions(user);
  if (error) return { erros: error };
  const item = await fetchApi(`${serverApi}${API_ALERTS_UN}`, options);
  return item;
};

export const addNewInfo = async (user, data) => {
  const { error, options } = setOptions(user, "POST", data);
  if (error) return { erros: error };
  const item = await fetchApi(`${serverApi}${API_INFOS}`, options);
  return item;
};

export const addNewAlert = async (user, data) => {
  const { error, options } = setOptions(user, "POST", data);
  if (error) return { erros: error };
  const item = await fetchApi(`${serverApi}${API_ALERTS}`, options);
  return item;
};

export const patchAlert = async (user, identifier, data) => {
  const { error, options } = setOptions(user, "PATCH", data);
  if (error) return { erros: error };
  const item = await fetchApi(
    `${serverApi}${API_ALERTS}${identifier}/`,
    options
  );
  return item;
};

export const patchInfo = async (user, identifier, data) => {
  const { error, options } = setOptions(user, "PATCH", data);
  if (error) return { erros: error };
  const item = await fetchApi(
    `${serverApi}${API_INFOS}${identifier}/`,
    options
  );
  return item;
};

export const postInfoAreas = async (user, identifier, data) => {
  const { error, options } = setOptions(user, "POST", data);
  if (error) return { erros: error };
  const item = await fetchApi(
    `${serverApi}${API_INFOS}${identifier}/areas/`,
    options
  );
  return item;
};

export const deleteInfoAreas = async (user, identifier, idArea) => {
  const { error, options } = setOptions(user, "DELETE");
  if (error) return { erros: error };
  const item = await fetch(
    `${serverApi}${API_INFOS}${identifier}/areas/${idArea}/`,
    options
  )
    .then(res => {
      return { status: res.status };
    })
    .catch(err => {
      return { status: 0, error: err.message };
    });
  return item;
};

export const postInfoResources = async (user, identifier, data) => {
  const { error, options } = setOptions(user, "POST", data);
  if (error) return { erros: error };
  const item = await fetchApi(
    `${serverApi}${API_INFOS}${identifier}/resources/`,
    options
  );
  return item;
};

export const patchInfoParameters = async (user, identifier, id, data) => {
  const { error, options } = setOptions(user, "PATCH", data);
  if (error) return { erros: error };
  const item = await fetchApi(
    `${serverApi}${API_INFOS}${identifier}/parameters/${id}/`,
    options
  );
  return item;
};

export const postInfoParameters = async (user, identifier, data) => {
  const { error, options } = setOptions(user, "POST", data);
  if (error) return { erros: error };
  const item = await fetchApi(
    `${serverApi}${API_INFOS}${identifier}/parameters/`,
    options
  );
  return item;
};

export const deleteInfoParameters = async (user, identifier, id) => {
  const { error, options } = setOptions(user, "DELETE");
  if (error) return { erros: error };
  const item = await fetch(
    `${serverApi}${API_INFOS}${identifier}/parameters/${id}/`,
    options
  )
    .then(res => {
      return { status: res.status };
    })
    .catch(err => {
      return { status: 0, error: err.message };
    });
  return item;
};

export const postInfoParameterAreas = async (
  user,
  identifier,
  idParam,
  data
) => {
  const { error, options } = setOptions(user, "POST", data);
  if (error) return { erros: error };
  const item = await fetchApi(
    `${serverApi}${API_INFOS}${identifier}/parameters/${idParam}/areas/`,
    options
  );
  return item;
};

export const deleteInfoParameterAreas = async (
  user,
  identifier,
  id,
  idArea
) => {
  const { error, options } = setOptions(user, "DELETE");
  if (error) return { erros: error };
  const item = await fetch(
    `${serverApi}${API_INFOS}${identifier}/parameters/${id}/areas/${idArea}/`,
    options
  )
    .then(res => {
      return { status: res.status };
    })
    .catch(err => {
      return { status: 0, error: err.message };
    });
  return item;
};

export const getAlertbyIdentifier = async (user, identifier) => {
  const { error, options } = setOptions(user);
  if (error) return { erros: error };
  const item = await fetchApi(
    `${serverApi}${API_ALERTS}${identifier}/`,
    options
  );
  return item;
};

export const getInfobyIdentifier = async (user, identifier) => {
  const { error, options } = setOptions(user);
  if (error) return { erros: error };
  const item = await fetchApi(
    `${serverApi}${API_INFOS}${identifier}/`,
    options
  );
  return item;
};

export const getInfoGrouped = async (user, qparams = "?status=UN") => {
  const { error, options } = setOptions(user);
  if (error) return { erros: error };
  const item = await fetchApi(
    `${serverApi}${API_INFOS_GROUPED}${qparams}`,
    options
  );
  return item;
};

export const searchAlerts = async (user, queryString) => {
  const { error, options } = setOptions(user);
  if (error) return { erros: error };
  const items = await fetchApi(
    `${serverApi}${API_ALERTS}${queryString}`,
    options
  );
  return items;
};

export const searchInfos = async (user, queryString) => {
  const { error, options } = setOptions(user);
  if (error) return { erros: error };
  const items = await fetchApi(
    `${serverApi}${API_INFOS}${queryString}`,
    options
  );
  return items;
};

export const searchCountries = async (user, queryString) => {
  const { error, options } = setOptions(user);
  if (error) return { erros: error };
  const items = await fetchApi(
    `${serverApi}${API_AREA_COUNTRIES}${queryString}`,
    options
  );
  return items;
};

export const searchRegions = async (user, queryString) => {
  const { error, options } = setOptions(user);
  if (error) return { erros: error };
  const items = await fetchApi(
    `${serverApi}${API_AREA_REGIONS}${queryString}`,
    options
  );
  return items;
};

export const getReview = async user => {
  const { error, options } = setOptions(user);
  if (error) return { erros: error };
  const items = await fetchApi(`${serverApi}${API_INFO_TO_REVIEW}`, options);
  return items;
};

export const patchReview = async (user, id, info_id, data) => {
  const { error, options } = setOptions(user, "PATCH", data);
  if (error) return { erros: error };
  const items = await fetchApi(`${serverApi}${API_INFOS}${info_id}/reviews/${id}/`, options);
  return items;
};

const setOptions = (user, type = "GET", data = null) => {
  if (!user.auth) return { error: "no data, user not allowed" };
  const options = {
    method: type,
    headers: {
      Authorization: `JWT ${user.data.json_token}`,
      "Content-Type": CONTENT_TYPES.json
    }
  };
  if (data !== null) options.body = JSON.stringify(data);
  return { error: null, options };
};

/**
 * Description [Access Django Api get generic api]
 * @param {string} endpoint
 * @param {object} options
 * @return {object} django response
 */
const fetchApi = (endpoint, options) => {
  try {
    return fetch(endpoint, options)
      .then(res => res.json())
      .then(json => json)
      .catch(err => {
        return { error: err.message };
      });
  } catch (err) {
    return { error: err.message };
  }
};
